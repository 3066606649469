/**=====================
     14. Chat CSS Start
==========================**/
.chat-box {
  .toogle-bar{
    display: none;
  }
  .chat-menu {
    max-width: 440px;
  }
  .people-list {
    .search{
      position: relative;
      .form-control{
        background-color: $light-body-bg-color;
        border: 1px solid $light-color;
        &::placeholder{
            color: $semi-dark;
        }
      }
      i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 14px;
        color: $light-gray;
      }
    }
    ul {
      padding: 0;
      li {
        padding-bottom: 20px;
        &:last-child{
          padding-bottom: 0;
        }
      }
    }
  }
  .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-right: 5px;
  }
  .about {
    float: left;
    margin-top: 5px;
    padding-left: 10px;
    .name {
      color: $dark-color;
      letter-spacing: 1px;
      font-weight: 500;
    }
    .typing {
      color: green;
      font-size: 12px;
    }
  }
  .unread-dot {
    position: absolute;
    background-color: #ff0c0c;
    height: 14px;
    width: 14px;
    border-radius: 14px;
    bottom: 3px;
    left: 4px;
  }
  .status {
    color: $semi-dark;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 5px;
    .chat-status{
      font-weight:600;
      color: $theme-body-font-color;
    }
    p{
      font-size:14px;
    }
  }
  .chat-right-aside{
    .chat {
      .chat-header {
        padding: 15px;
        border-bottom: 1px solid $light-color;
        img {
          float: left;
          width: 50px;
          height: 50px;
          box-shadow: 1px 1px 4px 1px $light-gray;
        }
        .chat-menu-icons{
          margin-top: 15px;
          li{
            margin-right:24px;
            a i{
              color: $theme-body-sub-title-color;
              font-size: 25px;
              cursor: pointer;
            }
          }
        }
      }
      .chat-msg-box {
        padding: 20px;
        overflow-y: auto;
        height: 560px;
        margin-bottom: 80px;
        .chat-user-img{
          margin-top: -35px;
        }
        .message-data {
          margin-bottom: 10px;
        }
        .message-data-time {
          letter-spacing: 1px;
          font-size: 12px;
          color: $semi-dark;
        }
        .message {
          color: $dark-color;
          padding: 20px;
          line-height: 1.9;
          letter-spacing: 1px;
          font-size: 14px;
          margin-bottom: 30px;
          width: 50%;
          position: relative;
        }
        .my-message {
          border: 1px solid $light-color;
          border-radius: 10px;
          border-top-left-radius: 0;
        }
        .other-message {
          background-color: $light ;
          border-radius: 10px;
          border-top-right-radius: 0;
        }
      }
      .chat-message {
        padding: 20px;
        border-top: 1px solid $light-body-bg-color;
        position: absolute;
        width: calc(100% - 15px);
        background-color: $white;
        bottom: 0;
        .smiley-box{
          background: $light-semi-gray;
          padding: 10px;
          display: block;
          border-radius: 4px;
          margin-right: 0.5rem;
        }
        .text-box{
          position: relative;
          .input-txt-bx {
            height: 50px;
            border: 2px solid var(--theme-deafult);
            padding-left: 18px;
            font-size: 12px;
            letter-spacing: 1px;
          }
          i{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            color: $light-gray;
            cursor: pointer;
          }
          .btn{
            font-size: 16px;
            font-weight: 500;
            padding: 0.74rem 1.75rem;
            border-radius: 0 5px 5px 0; 
          }
        }
      }
    }
  }
  .chat-menu {
    border-left: 1px solid $light-color;
    .tab-pane {
      padding: 0 15px;
    }
    ul{
      li{
        .about{
          .status{
            i{
              font-size: 10px;
            }
          }
        }
      }
    }
    .user-profile {
      margin-top: 30px;
      .user-content{
        h5{
          margin: 25px 0;
        }
        hr{
          margin: 25px 0;
        }
        p{
          font-size: 16px;
        }
      }
      .border-tab.nav-tabs .nav-item .nav-link.active, .border-tab.nav-tabs .nav-item .nav-link.show, .border-tab.nav-tabs .nav-item .nav-link:focus{
        border-bottom: 0;
      }
      .image {
        position: relative;
        .icon-wrapper {
          position: absolute;
          bottom: 0;
          left: 55%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: $white;
          cursor: pointer;
          overflow: hidden;
          margin: 0 auto;
          font-size: 14px;
          box-shadow: 1px 1px 3px 1px $light-color;
        }
        .avatar img {
          border-radius: 50%;
          border: 5px solid $light-color;
        }
      }
      .border-right {
        border-right: 1px solid $light-color;
      }
      .follow {
        margin-top: 0;
        .follow-num {
          font-size: 22px;
          color: $black;
        }
        span {
          color: $theme-font-color;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
      .social-media a {
        color: $semi-dark;
        font-size: 15px;
        padding: 0 7px;
      }
      .chat-profile-contact{
        p{
          font-size:14px;
          color:$semi-dark;
        }
      }
    }
    .nav{
      margin-bottom: 20px;
    }
    .nav-tabs .nav-item {
      width:50%;
      a {
        padding: 15px!important;
        color: $semi-dark!important;
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 500;
        height: 60px;
        display:flex;
        align-items:center;
        justify-content:center;
      }
      .material-border{
        border-width:1px;
        border-color:var(--theme-deafult);
      }
      .nav-link.active {
        color: $black!important;
      }
    }
  }
  .chat-history{
     .call-content {
       display: flex;
       align-items: center;
       justify-content: center;
       min-width: 300px;
     }
     .total-time h2 {
       font-size: 50px;
       color: $light-semi-gray;
       font-weight: 600;
       margin-bottom: 30px;
     }
     .receiver-img{
       margin-top: 55px;
       img{
          border-radius: 5px;
       }
     }
     .call-icons{
       margin-bottom: 35px;
       ul{
         li {
           width: 60px;
           height: 60px;
           border: 1px solid $light-color;
           border-radius: 50%;
           padding: 12px;
           + li{
             margin-left: 10px;
           }
           a {
             color: #999;
             font-size: 25px;
           }
         }
       }
     }
   }
}
.chat-left-aside{
  > .media{
    margin-bottom: 15px;
  }
  .people-list{
    height: 625px;

    .list {
      .actve-user {
        padding: 5px;
        cursor: pointer;
      }
      .active {
        background-color: rgba(107, 52, 52, 0.05);
        border-radius: 5px;
      }
    }
  }
  ul li{
    position: relative;
  }
}
.status-circle{
  width: 12px;
  height: 12px;
  position: absolute;
  top:4px;
  left:44px;
  border-radius: 50%;
  border:2px solid $white;
}
.away{
  background-color: $warning-color;
}
.online{
  background-color: $success-color;
}
.offline{
  background-color: $danger-color;
}
.chat-container{
  .aside-chat-left{
    width:320px;
  }
  .chat-right-aside{
    width:320px;
  }
}
.call-chat-sidebar{
  max-width: 320px;
}
.call-chat-sidebar,.chat-body{
    .card{
        .card-body{
            padding: 15px;
        }
    }
}

.typing-text {
  color: green;
  font-size: 14px;
  margin-left: 10px;
}

.transferred-user-from {
  margin-top: -20px;
  margin-bottom: 0px;
  font-size: 10px;
  color: grey
}

.chat-user {
  border: 0.5px solid #9e9d9d;
  padding: 4px;
}

.agent-avatar {
  height: 52px;
  width: 52px;
}

.unread-message-count {
  height: 15px;
  width: 15px;
  background-color: #ed222b;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50px;
  color: white;
  font-size: 10px;
}

.user-chat-status {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  // // position: absolute;
  // top: 10px;
  // left: 4px;
}

.marker-container {
  margin: 5px !important;
  padding: 0px !important;
  display: flex;
  // margin-top: 5px !important;
  // margin-left: 5px !important;
}

.chat-input-box {
  width: 100%;
  background-color: white;

  .input-container {
    display: flex;
    padding-right: 0px;
    flex: 1;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 8px;
  }

  .chat-send-button {
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.12);
    background-image: unset;
    background-size: 25px 25px;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.6;
    transition: opacity 0.3s ease 0s;
    border-radius: 100%;
    padding: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 16px;
    position: relative;
    left: 16px;
    transform: scaleX(-1);
  }
  
  .chat-send-button:hover {
    opacity: 1;
  }

  .input-box {
    border: 0px solid white;
  }

  .input-box:focus {
    outline: none !important;
    border: 0px solid white !important;
  }
}

.message-item {
  margin: 10px auto;
  padding: 0px 35px 0px 25px;

  .message-item-time {
    margin-left: 15px;
    margin-top: 5px;
    margin-right: 4px;
    opacity: 0.7;
    font-size: 11px !important;
    color: rgb(0, 0, 0) !important;
    align-items: center;
  }

  .incoming-message-item-time {
    margin-right: 15px;
  }

  .incoming-msg-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.message-item.incoming {
  flex-direction: row-reverse;
}

.message-item {
  .download-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    border: 1px solid grey;
    font-size: 16px;
    color: grey
  }

  .pdf-logo {
    height: 40px;
  }
  .pdf-container {
    .pdf-name {
      word-break: break-word;
      text-align: left;
      padding: 0px 10px;
      margin-left: 10px;
    }
  }
  
}

.link-preview-container {
  text-align: left;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  border: 1px solid #ccc;
  color: black;
  transition: 0.3s all ease;
  height: fit-content;
  margin-top: 10px;
}

.LowerContainer {
  .Title {
    font-size: 14px;
    margin-bottom: 5px !important;
  }

  .Secondary.SiteDetails {
    margin-top: 0px;
    span {
      font-size: 10px !important;
    }
  }
}



/**=====================
    14.  Chat CSS Ends
==========================**/
