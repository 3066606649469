/**=====================
    Typography css start
==========================**/
//Custom css Start
body {
    font-size: $body-font-size;
    overflow-x: hidden;
    font-family: $font-rubik, $font-serif;
    color: $theme-body-font-color;
}

ul {
    padding-left: $ul-padding-left;
    list-style-type: none;
    margin-bottom: 0;
}

* a {
    color: var(--theme-deafult);
    text-decoration: none;
    letter-spacing: 1px;
}

a {
    &:hover {
        text-decoration: none;
        color: var(--theme-deafult);
    }
}

*.btn:focus {
    box-shadow: $btn-focus-box-shadow !important;
}

p {
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    letter-spacing: $paragraph-letter-spacing;
}

code {
    color: $code-tag-color !important;
    background-color: $code-tag-bg-color;
    padding: $code-tag-padding;
    margin: $code-tag-margin;
    border-radius: $code-tag-border-radious;
}

blockquote {
    border-left: $blockquote-border;
    padding: $blockquote-padding;

    &.text-center {
        border: none;
        padding: $blockquote-padding;
    }

    &.text-right {
        border-left: none;
        border-right: $blockquote-border;
        padding: $blockquote-padding;
    }
}

:focus {
    outline-color: $all-focus-outline-color;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

svg {
    vertical-align: baseline;
}

input {
    &:focus {
        outline-color: $transparent-color;
    }
}

label {
    margin-bottom: .5rem;
}

.media-widgets {
    .media-body {
        margin-bottom: 30px;
    }
}

//Custom css End

//Typography css start
.typography {

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin: 20px 0;
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    small {
        padding-left: $small-tag-padding;
        color: $small-tag-color;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.2;
}
.h1 {
    font-size: 2.5rem;
}
.h2 {
    font-size: 2rem;
}
.h3 {
    font-size: 1.75rem;
}
.h4 {
    font-size: 1.5rem;
}
.h5 {
    font-size: 1.25rem;
}
.h6 {
    font-size: 1rem;
}

pre {
    background-color: $pre-tag-bg-color;
    padding: $pre-tag-padding;
}

//chatbot css 16-07-2021

.customChatboardBody .customChatboard {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: rgb(237, 34, 43);
    padding: 15px 15px 7px;
    border-radius: 25px;
  }
  
  .activeCustomerName {
    box-shadow: #0000001f 0px 5px 50px, #0000001f 0px 3px 50px;
    background-color: #fff;
    font-size: 20px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 12px 20px;
  }

  .activeCustomerName span.targetUserName {
        font-size: 20px;
        color:rgb(0, 0, 0);
        font-weight: bold;
        font-family: FSAlvar, arial;
    }
  
  .chatComponent {
    background: rgb(255, 255, 255);
    bottom: 30px;
    right: 30px;
    width: 100%;
    height: 100vh;
  }
  
  .activeChatContent {
    //   border: 1px solid #ccc;
    background: transparent;
    height: 94vh;
    overflow: hidden scroll;
    // padding: 0 5px;
  }
  
  .outgoing .outgoing_msg p {
    display: flex;
    flex-wrap: wrap;
    // justify-content: flex-end;
    align-items: center;
    max-width: 92%;
    margin-bottom: 0;
  }
  
  .outgoing .outgoing_msg p span {
    // background: rgb(243, 242, 252);
    // border-top-left-radius: 10px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    // // padding: 10px;
    border: transparent;
    // // margin: 10px auto;
    font-size: 15px;
    // position: relative;
    // width: 80%;
  }
  
  .outgoing .outgoing_msg p span i {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 10px;
  }
  
  .message-item .outgoing_msg_icon {
    position: unset;
    top: 0px;
    left: 8px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin: 5px 5px 25px;
    align-self: flex-end;
  }
  
  .incoming .incoming_msg p {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    width: 92%;
    margin-left: 8%;
    margin-bottom: 0;
  }
  
  .incoming .incoming_msg p span {
    // background: rgb(244, 240, 240);
    // padding: 10px;
    // border: transparent;
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;
    // border-bottom-left-radius: 10px;
    // margin: 10px auto;
    // font-size: 15px;
    // position: relative;
    // width: 80%;
  }
  
  .incoming .incoming_msg p span i {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 10px;
  }
  
  .incoming .incoming_msg p .incoming_msg_icon {
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: rgb(244, 240, 240);
    border-radius: 50%;
    box-sizing: border-box;
    padding: 8px;
    margin-right: 10px;
  }
  
  .activeChatContent.activeChatContent_style {
    min-height: 250px;
  }
  
/* width */
.activeChatContent::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  .activeChatContent::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .activeChatContent::-webkit-scrollbar-thumb {
    background: rgb(221, 221, 221);
    border-radius: 4px;

  }
  
  /* Handle on hover */
  .activeChatContent::-webkit-scrollbar-thumb:hover {
    background: #d4d3de; 
  }


  .activeChatContent.activeChatContent_style .msg_option {
    min-height: 150px;
    padding: 10px 0px;
  }
  
  .activeChatContent button.msg_option_style.btn.btn-secondary.btn.btn-outline-secondary.btn-sm {
    flex: unset;
    margin: 4px;
    padding: 8px 16px;
    border-radius: 20px;
    border: 2px solid #ed222b !important;
    background-color: #fff !important;
    color: #ed222b;
    box-shadow: unset;
    font-size: 17px;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
    opacity: 0.3s ease 0s;
    line-height: 1.18;
    animation-name: showoption;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.515, 0.01, 1, 0.41);
    display: inline-block;
  }
  
  .activeChatContent button.msg_option_style.btn.btn-secondary.btn.btn-outline-secondary.btn-sm:hover {
    background-color: #ed222b !important;
    color: #fff;
    box-shadow: #d40f1800 0px 5px 50px, #d40f186e 0px 3px 6px;
  }
  
  .activeChatContent .msg_option_center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 100px;
    padding: 0 30px;
    width: 100%;
  }
  
  .activeChatContent .msg_option_center p {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    direction:"rtl";
  }
  
  .primary-button-style {
    color: #ed222b !important;
    background-color: #fff !important;
  }
  
  .primary-button-style:hover {
    color: #fff !important;
    box-shadow: #d40f1800 0px 5px 50px, #d40f186e 0px 3px 6px;
  }
  
  .secondary-button-style {
    color: #39b44a !important;
    background-color: #fff !important;
    border-color: #39b44a !important;
  }
  
  .secondary-button-style:hover {
    color: #fff !important;
    background-color: #39b44a !important;
    border-color: #39b44a !important;
    box-shadow: #39b44a00 0px 5px 50px, #39b44a7d 0px 3px 6px;
  }
  
  .OnlineCustomerFooter {
    padding: 5px 0px 0px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.OnlineCustomerFooter .form-control-style{
    height: 45px;
    border-radius: 0%;
}

.OnlineCustomerFooter .form-control-style:focus{
    box-shadow: none!important;
    border: 1px solid #ced4da!important;
}

.OnlineCustomerFooter .form-control-button{
    border-radius: 0%;
    height: 45px;
    min-width: 60px;
    max-width: 10%;
}

//Typography css end
.owl-theme {
    .owl-dots {
        .owl-dot {
            span {
                background: lighten($primary-color, 20%);
            }

            &.active,
            &:hover {
                span {
                    background: var(--theme-deafult);
                }
            }
        }
    }

    .owl-nav {
        &.disabled {
            &+.owl-dots {
                margin-bottom: -10px;
                margin-top: 25px;
            }
        }
    }
}

.click2edit {
    &~.note-editor {
        &.note-frame {
            margin-bottom: 30px;
        }
    }
}

.note-editor {
    &.note-frame {
        border-color: $light-color;
    }
}

.note-editor {
    &.note-frame {
        border-color: $light-color;
    }
}

// modal page
.modal {
    .modal-body {
        .card {
            box-shadow: none !important;
        }
    }
    .close {
        font-weight: 400;
    }
}

.modal-dialog {
    .modal-content {
        .modal-body {
            p {
                a {
                    margin-right: 0;
                }
            }
        }
    }
}

// index page notify
.alert-theme {
    button {
        top: 33px !important;
        right: 30px !important;
        color: var(--theme-deafult);
    }

    span {
        +span {
            +span {
                // border: 2px solid var(--theme-deafult);
                background-color: lighten($primary-color, 40%);
                color: var(--theme-deafult);
                border-radius: 4px;
                box-shadow: 0 2px 14px -3px rgba($black, 0.2);
            }
        }
    }

    i {
        padding: 20px;
        padding-right: 0 !important;
        margin-right: 20px !important;
    }

    .progress {
        margin-top: -2px !important;
        height: 2px;

        .progress-bar {
            background-color: var(--theme-deafult);
        }
    }
}

.alert-copy {
    border: 1px solid var(--theme-deafult);
    line-height: 1;
    display: inline-block;
    width: 300px;
    color: $white;
    background-color: var(--theme-deafult);

    .close {
        box-shadow: none;
        color: $white;
        line-height: 1.4;
        font-weight: 100;

        &:focus {
            outline: none;
        }
    }
}

.ace-editor {
    height: 400px;
    width: 100%;
}

.editor-toolbar {
    width: 100% !important;
}

.CodeMirror {
    top: 0 !important;
}
.CodeMirror-fullscreen {
    top: 50px !important;
    z-index: 9;
}

// rangeslider
.irs-from,
.irs-to,
.irs-single {
    background: var(--theme-deafult);

    &:after {
        border-top-color: var(--theme-deafult);
    }
}

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
    background-color: var(--theme-deafult);
}

// sweetalert css
.swal-button {
    background-color: var(--theme-deafult);

    &:active {
        background-color: darken($primary-color, 5%);
    }
}

.swal-button--cancel {
    background-color: #EFEFEE !important;
}

.clipboaard-container {
    h6 {
        line-height: 1.6;
    }
}

fieldset {
    .bootstrap-touchspin-postfix,
    .bootstrap-touchspin-prefix {
        border-radius: 0;
        padding: 0.4rem 0.75rem;
    }
    .input-group-prepend {
        & ~ .form-control {
            height: 39px;
        }
    }
    .input-group-lg {
        .input-group-prepend,
        .input-group-append {
            .btn {
                padding: 0.8rem 1.75rem;
            }
        }
    }
}

.f1 .f1-steps .f1-step .f1-step-icon {
    padding: 12px;
}

.form-inline .form-group {
    display: flex;
}

.input-group-text {
    i {
        line-height: 1.5;
    }
}

// tap-top
.tap-top {
	text-align: center;
	cursor: pointer;
	display: none;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 5;
	color: #fff;
	background: var(--theme-deafult);
	border: none;
	padding: 7px;
	box-shadow: 0 0 10px var(--theme-deafult);
	opacity: 0.5;
	transition: all 0.3s ease;
  
	&:hover {
	  transition: all 0.3s ease;
	  opacity: 1;
	}
  
	svg {
	  width: 20px;
	}
}

/**=====================
    Typography css Ends
==========================**/